import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import App from './App';
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import './index.css'

// Router history
const history = createBrowserHistory();

// Root element
const rootElement = document.getElementById('root');

const theme = extendTheme({
  fonts: {
    heading: "Quicksand",
    body: "Quicksand",
  },
})


ReactDOM.render(
    <ChakraProvider theme={theme}>
      <Router history={history}>
        <App/>
      </Router>
    </ChakraProvider>,
    rootElement,
);
