import React from 'react';
import {Flex, Heading, Stack, HStack, Container, Button} from '@chakra-ui/react'
import heroImage from './assets/hero.png'
import styled from '@emotion/styled';

function LandingPage() {
  return (
      <Flex flex={1} direction={'column'}>
        <HeroBackground direction={'row'}  flex={1} align={"center"} >
          <Container maxW="container.lg">
            <Flex direction={"row"} flex={1} justify={"space-between"} align={"center"}>
              <Stack spacing={4}>
                <Heading as={"h1"} size={"2xl"} color={"white"}>My Imaan App</Heading>
                <Heading as={"h2"} size={"lg"} color={"white"}>An islamic buddy to help you with your imaan.<br/>
                Reminders, education, mental health - a safe space for you.</Heading>
                <Button colorScheme={"purple"} size={"lg"}>Coming Soon.</Button>
              </Stack>
            </Flex>
          </Container>
        </HeroBackground>
      </Flex>
  )
}

const HeroBackground = styled(Flex)`
  background-image: url(${heroImage});
  background-size: cover;
  display: flex;
`;

export default LandingPage;
