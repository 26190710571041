import {Switch} from 'react-router';
import LandingPage from './LandingPage';

function App() {
  return (
      <Switch>
        <LandingPage/>
      </Switch>
  )
}

export default App;
